// Libraries
import * as React from 'react'

// Middleware
import CheckoutMiddleware from '../../middleware/checkoutMiddleware'

// Components
import Layout from '../../components/layout'
import CardDetails from '../../components/cardDetails'
import DuoSection from '../../components/duoSection'
import Seo from '../../components/seo'
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'

const pk = process.env.GATSBY_STRIPE_PUBLISHABLE_KEY
const stripePromise = loadStripe(pk)

const Pay = () => {
	return (
		<CheckoutMiddleware>
			<Layout className="nav-blue-all pay">
				<Seo title="Payment" />
				<DuoSection>
					<div>
						<h1 className="generic-template__title">Ballot Entry</h1>
					</div>
					<div className="white-section">
						<div className="checkout">
							<h2>Payment<br />Information</h2>

							<p>If you are successful in winning tickets, the card details you enter below will be used
								to collect payment. We will not take any money if you do not win the ballot.</p>

							<Elements stripe={stripePromise}>
								<CardDetails />
							</Elements>
						</div>
					</div>
				</DuoSection>
			</Layout>
		</CheckoutMiddleware>
	)
}

export default Pay